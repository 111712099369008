import React, { Component } from "react"
import BookingWrapper from "../components/booking-screen/booking-wrapper"
import Layout from "../components/layout"
import SEO from "../components/seo"

class TheBallroomPage extends Component {
  state = {}
  render() {
    return (
      <Layout max>
        <SEO title="Book The Ballroom" />
        <BookingWrapper title="The Ballroom" />
      </Layout>
    )
  }
}

export default TheBallroomPage
